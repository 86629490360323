@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;800&display=swap');

$blue: #4472C4;
$green: #7acb63;

.blue-bg {
  background-color: $blue !important;
  color: white !important;
}


.btn-success {
  background-color: $green !important;
  border-color: $green !important;
}


html, body, #root
{
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}
#root > .container-fluid { height: 100%;}
main { min-height: 100%; padding: 0 0 5rem 0; }




.yumble-header
{
  img {
    max-height: 3rem;
  }
}


.yumble-footer
{
  img {
    width: 1em;
    height: 1em;
  }
}

.launch-box {

  align-items: center !important;

  span {
    border: 1px solid black;
    display: flex;
    width: 4rem;
    height: 4rem;
    align-items: center !important;
    justify-content: center !important;
    font-size: 3rem !important;
    padding: 1rem !important;
    margin: 0 1rem 0 1rem !important;
  }
  button {
    height: 2rem;
    width: 2rem;
    align-items: center !important;
    text-align: center !important;
    justify-content: center !important;
    padding: 0 !important;
    border-radius: 0 !important;
  }
}


.yumbleCheck {

  .form-check-input[type=radio] {
    border-radius: 0;
  }
  .form-check-input:checked[type=radio] {
    --bs-form-check-bg-image: url('../public/images/icon-radio.png') !important;
    border-color: $green;
  }

}

.while-class {

  .whileProductName {

    h4 { font-size: 100%; }


  }

  .whileProductGrid
  {

    .launch-box {

      align-items: center !important;

      span {
        border: 1px solid black;
        display: flex;
        width: 3rem;
        height: 3rem;
        align-items: center !important;
        justify-content: center !important;
        font-size: 2rem !important;
        padding: 0.6rem !important;
        margin: 0 0.5rem 0 0.5rem !important;
      }
    }



  }

}